<template>
  <q-padder top="xxs" horizontal="xs" class="footer-section">
    <q-padder bottom="xxs" horizontal="xs">
      <q-padder vertical="sm" class="bt-blue">
        <q-text variant="ui-small" ink="label" bold>{{
          content.footerHeadOurproducts
        }}</q-text>
        <q-row wrap>
          <q-text
            v-for="(product, index) in products"
            :key="index"
            :title="product.name"
            variant="ui-tiny"
            ink="muted"
            class="link-text product"
            @click.native="goToProduct(product.url)"
          >
            {{ product.name }}
          </q-text>
        </q-row>
      </q-padder>
      <q-padder vertical="sm" class="bt-blue">
        <q-text variant="ui-small" ink="label" bold>{{
          content.footerHeadAboutqoala
        }}</q-text>
        <q-row wrap>
          <q-text
            v-for="(service, index) in services"
            :key="index"
            :title="service.name"
            variant="ui-tiny"
            ink="muted"
            class="link-text service"
            @click.native="service.isExternal ? goToBlogService(service.url) : goToProduct(service.url)"
          >
            {{ service.name }}
          </q-text>
        </q-row>
      </q-padder>
      <q-padder vertical="sm">
        <q-text variant="ui-small" ink="label" bold>{{
          content.footerHeadContactus
        }}</q-text>
        <q-spacer top="xs">
          <q-text
            variant="ui-tiny"
            ink="muted"
            v-html="content.footerHeadContactusAddress"
          />
        </q-spacer>
      </q-padder>

      <!-- Footer Section -->
      <q-padder top="xxs">
        <q-text variant="ui-small" ink="label" bold>{{
          content.collaborateLabel
        }}</q-text>
        <q-spacer top="xs">
          <q-row center>
            <img
              v-lazy="mjpLogo"
              src="https://assets.qoala.app/images/general/Logo-MJP.png"
              alt="MJP Logo"
              title="MJP Logo"
              width="30px"
            />
            <q-spacer left="xs">
              <q-text
                variant="ui-tiny"
                ink="muted"
                upper-case
                style="line-height: 1.35"
              >
                {{ content.mjpLabel }}
              </q-text>
            </q-spacer>
          </q-row>
        </q-spacer>
      </q-padder>

      <q-padder top="xs">
        <q-text
          variant="ui-tiny"
          ink="muted"
          upper-case
          style="line-height: 1.35"
        >
          {{ content.ojkNumber }}
        </q-text>
      </q-padder>

      <q-padder top="md">
        <q-text variant="ui-small" ink="label" bold>{{
          content.footerHeadOjk
        }}</q-text>
        <q-spacer top="xs">
          <q-row center>
            <img
              v-lazy="ojkLogo"
              src="https://assets.qoala.app/images/placeholder_all_size.png"
              alt="OJK Logo"
              title="OJK Logo"
              width="65px"
            />
          </q-row>
        </q-spacer>
      </q-padder>
      <q-padder vertical="xs">
        <q-text variant="ui-tiny" ink="muted">{{
          copyRightFooterContent
        }}</q-text>
      </q-padder>
    </q-padder>
  </q-padder>
</template>

<script>
/**
 * Footer Section - Homepage
 *
 * @author <doni.samalo@qoala.id> updated on: 14 December 2020
 */
import {
  Padder as QPadder,
  Text as QText,
  Spacer as QSpacer,
  Row as QRow,
} from '@qoala/ui';

export default {
  components: {
    QPadder,
    QText,
    QSpacer,
    QRow,
  },
  props: {
    content: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      ojkLogo: 'https://assets.qoala.app/images/homepage/logo-ojk.png',
      mjpLogo: 'https://assets.qoala.app/images/general/Logo-MJP.png',
      products: [
        // {
        //   name: this.content.footerHeadOurproductsSmartphone,
        //   url: 'landing-smartphone',
        // },
        {
          name: this.content.footerHeadOurproductsCar,
          url: 'landing-car',
        },
        {
          name: this.content.footerHeadOurproductsMotorcycle,
          url: 'landing-motor',
        },
        {
          name: this.content.footerHeadOurproductsHealth,
          url: 'landing-health',
        },
        {
          name: this.content.footerHeadOurproductsHCP,
          url: 'landing-hcp',
        },
        {
          name: this.content.footerHeadOurproductsLife,
          url: 'landing-life',
        },
        {
          name: this.content.footerHeadOurproductsTropicaldisease,
          url: 'purchase-tropical-disease',
        },
      ],
      services: [
        {
          name: this.content.footerHeadAboutqoalaAboutus,
          url: 'about-about-us',
        },
        {
          name: this.content.footerHeadAboutqoalaPrivacypolicy,
          url: 'about-privacy-policy',
        },
        {
          name: this.content.footerHeadAboutqoalaDisclaimer,
          url: 'about-disclaimer-chapter',
        },
        {
          name: this.content.footerHeadAboutqoalaTos,
          url: 'about-term-of-service',
        },
      ],
    };
  },

  computed: {
    copyRightFooterContent() {
      const copyRightContent = this.content.footerCopyright;
      const currentYear = new Date().getFullYear();
      return copyRightContent.replace('2020', currentYear);
    },
  },
  methods: {
    goToProduct(url) {
      this.$router.push(this.localePath(url));
    },
    goToBlogService(url) {
      this.$helper.openNewTab(url, '_blank');
    },
  },
};
</script>
<style lang="scss" scoped>
.footer-section {
  background: #ffffff;
  .link-text {
    padding-right: 7px;
    margin-right: 7px;
    margin-top: 8px;
    height: 12px;
    border-right: 1px solid #707070;
    transition: all 0.2s;
    cursor: pointer;
    &:hover {
      font-weight: bold;
    }
    &:last-child {
      border-right: 0;
      padding-right: 0;
    }
    &:nth-child(4) {
      @media screen and (min-width: 424px) {
        border-right: 0;
        padding-right: 0;
      }
    }
  }
  .bt-blue {
    border-bottom: 1px solid rgba(148, 201, 222, 0.3);
  }
}
</style>
